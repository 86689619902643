import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import {OptionDefinition} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import * as React from "react";
import {useContext} from "react";
import {ProfileContext} from "../../context/ForecastProfileContext";
import {ForecastProfile} from "../../live-monitoring/generated-src";
import {MultiselectProps, Spinner} from "@amzn/awsui-components-react";
import {useHistory} from "react-router-dom";

export interface IndividualForecastSearchProps {
    selectedProfiles?: string[];
}

export default function IndividualForecastSearch(props: IndividualForecastSearchProps) {
    const history = useHistory();
    const sortProfiles = (a: ForecastProfile,b: ForecastProfile) => {
        if (a.name === undefined || b.name === undefined) {
            throw Error("Label must be defined")
        }
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    }
    const profiles = useContext(ProfileContext)
    if (!profiles.length){
        return <Spinner size={"large"}/>
    }
    const profileOptions: MultiselectProps.Option[] = profiles.sort(sortProfiles).map(profile => {
        return {
            label: profile.name,
            value: profile.forecastProfileId
        }
    })
    const [selectedProfileOptions, setSelectedProfileOptions] = React.useState<readonly OptionDefinition[]>(
        () => {
            const selectedOptions = props.selectedProfiles?.map(profileName => profileOptions.find(opt => opt.label === profileName)) || []
            return selectedOptions as OptionDefinition[];
        });

    const profileSelectionChange = ((newSelection: string[]) =>
            history.push(generateUrl(newSelection)))

    return (
        <Multiselect
            options={profileOptions}
            onChange={(event) => {
                setSelectedProfileOptions(event.detail.selectedOptions)
                profileSelectionChange(event.detail.selectedOptions.map(opt => opt.label || ""))
            }}
            selectedOptions={selectedProfileOptions}
            placeholder="Select specific forecasts"
            filteringType="auto"

        />
    )
}

function generateUrl(ids: string[]) {
    if (ids.length) {
        return `/search?&ids=${ids.join(",")}`
    }
    return "/"
}
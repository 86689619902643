import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import {ColumnLayout, ExpandableSection} from "@amzn/awsui-components-react";
import ForecastChart, {ForecastChartProps} from "../chart/ForecastChart";
import {ParentToChildMapping} from "../../config/ProfileGroupMappings";
import {ChartData} from "../../live-monitoring/generated-src";
import {ActionableCollectionCardProps, CollectionCardProps} from "./ChartCollection";

export interface StaticDualExpandableChartProps {
    leftMapping: ParentToChildMapping;
    rightMapping: ParentToChildMapping
}
export interface DualExpandableChartProps extends
    StaticDualExpandableChartProps,
    ActionableCollectionCardProps,
    CollectionCardProps,
    ForecastChartProps {}

export default function DualExpandableChartContainer(props: DualExpandableChartProps) {
    const leftChildren = props.leftMapping.childId
    const rightChildren = props.rightMapping.childId

    let childIds: string[] = [];
    for (let i = 0; i < leftChildren.length; i++) {
        childIds.push(leftChildren[i], rightChildren[i])
    }
    const leftChildChartData = leftChildren
        .map(id => props.chartDataMap.get(id))
        .sort((a, b) => {
            if (a === undefined || b === undefined) {
                throw Error("No chart found")
            }
            return a.name.localeCompare(b.name)
        }).reverse();

    const rightChildChartData = rightChildren
        .map(id => props.chartDataMap.get(id))
        .sort((a, b) => {
            if (a === undefined || b === undefined) {
                throw Error("No chart found")
            }
            return a.name.localeCompare(b.name)
        }).reverse();

    let childChartData: (ChartData | undefined)[] = []
    for (let i = 0; i < leftChildChartData.length; i++) {
        childChartData.push(
            leftChildChartData[i],
            rightChildChartData[i]
        );
    }
    const leftChartData = props.chartDataMap.get(props.leftMapping.parentId);
    const rightChartData = props.chartDataMap.get(props.rightMapping.parentId);

    return (
        <Container
            footer={
                childChartData.length !== 0 &&
                <ExpandableSection
                    header="Expand to Individual Forecast Profiles"
                    variant="footer"
                    onChange={expanded => {
                        if (expanded) {
                            props.addActiveCharts(childIds)
                        } else {
                            props.removeCharts(childIds)
                        }
                    }}>
                    <ColumnLayout variant={"text-grid"} columns={2}>
                        {
                            childChartData.map(
                                chart => {
                                    return (
                                            <ForecastChart
                                                chartData={chart}
                                                dataLoading={props.dataLoading}
                                                dateRange={props.dateRange}
                                                chartSync={props.chartSync}
                                                triggerChartCallOnScaleChange={props.triggerChartCallOnScaleChange}
                                                key={chart?.forecastProfileId}
                                            />
                                    )
                                })
                        }
                    </ColumnLayout>
                </ExpandableSection>
            }>
            <ColumnLayout variant={"text-grid"} columns={2} >
                        <ForecastChart
                            chartData={leftChartData}
                            dataLoading={props.dataLoading}
                            dateRange={props.dateRange}
                            triggerChartCallOnScaleChange={props.triggerChartCallOnScaleChange}
                            chartSync={props.chartSync}
                        />
                    <ForecastChart
                        chartData={rightChartData}
                        dateRange={props.dateRange}
                        dataLoading={props.dataLoading}
                        triggerChartCallOnScaleChange={props.triggerChartCallOnScaleChange}
                        chartSync={props.chartSync}
                    />
            </ColumnLayout>
        </Container>
    )
}
import DualExpandableChartContainer, {StaticDualExpandableChartProps} from "../../components/chart-containers/DualExpandableChartContainer";
import ChartCollection from "../../components/chart-containers/ChartCollection";
import React from "react";
import {PROFILE_GROUP_MAPPING, ProfileGroup} from "../../config/ProfileGroupMappings";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

export interface HomepageProps {
    searchHeader: React.ReactNode;

}

export default function HomepageContent() {

    const groupMapping = PROFILE_GROUP_MAPPING()
    const postOPF = groupMapping.get(ProfileGroup.PostOPFRWTraffic) || [];
    const physicalOrderRate = groupMapping.get(ProfileGroup.PhysicalOrderRate) || [];

    const profileIdsToRetrieve: string[] = []
    for (let i = 0; i < postOPF.length; i++) {
        profileIdsToRetrieve.push(postOPF[i].parentId, physicalOrderRate[i].parentId)
    }

    const profileHierarchyForExpandableCard: StaticDualExpandableChartProps[] = []
    for (let i = 0; i < postOPF.length; i++) {
        profileHierarchyForExpandableCard.push(
            {
                leftMapping: physicalOrderRate[i],
                rightMapping: postOPF[i],
            });
    }
    return (
            <SpaceBetween size="s">
                <ChartCollection
                    initialActiveProfiles={profileIdsToRetrieve}
                    containerDefinition={DualExpandableChartContainer}
                    staticContainerProps={profileHierarchyForExpandableCard}
                    addDynamicProfileSetting={true}
                />
            </SpaceBetween>
    )

}



// Promise polyfill
import 'core-js/features/promise';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import { initializeAppSettings } from './config/AppSettings';
import initializeCloudWatchRUM from "./config/CloudWatchRum";

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);
  initializeCloudWatchRUM();

  render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
})();

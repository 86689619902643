import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import {getAppSetting} from "./AppSettings";

export default function initializeCloudWatchRUM() {
    if (getAppSetting("stage") === "local"){
        return;
    }
    const rumConfig = getAppSetting("rumConfig")
    try {
        const config: AwsRumConfig = {
            sessionSampleRate: 1,
            guestRoleArn: rumConfig["guestRoleArn"],
            identityPoolId: rumConfig["identityPoolId"],
            endpoint: rumConfig["endpoint"],
            telemetries: ["errors", "performance", ["http",{recordAllRequests: true}]],
            allowCookies: true,
            enableXRay: false
        };
        const APPLICATION_VERSION: string = '1.0.0';

        const awsRum: AwsRum = new AwsRum(
            rumConfig['rumApplicationId'],
            APPLICATION_VERSION,
            rumConfig['rumApplicationRegion'],
            config
        );
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
        throw error
    }
}
import {ChartData, DefaultApi} from './generated-src';
import {getAppSetting} from '../config/AppSettings';
import {Configuration} from "./generated-src";


export class LiveMonitoringApi extends DefaultApi {
    public async batchGetCharts(ids, resolution, startTime, endtime) {
        const promises: Array<Promise<Array<ChartData>>> =  []
        this.splitarray(ids, 8).forEach(batch => {
            promises.push(super.getCharts(batch, resolution, startTime, endtime)
                .then(resp => {
                    return resp.data
                }))
        })

        return Promise.all(promises)
            .then((responses) => {
                const results: ChartData[] = []
                responses.forEach(resp => results.push(...resp))
                return {
                    data: results
                }
            })

    }


    private splitarray(input, spacing) {
        let output: string[][] = [];
        for (let i = 0; i < input.length; i += spacing) {
            output[output.length] = input.slice(i, i + spacing);
        }
        return output;
    }
}

export default function () {
    const apiBasePath = `https://${getAppSetting('apiUrl')}/jwt`;
    return new LiveMonitoringApi(new Configuration({basePath: apiBasePath}));
}
import {SelectProps} from "@amzn/awsui-components-react";

const PLATFORM = [
  'All',
  'iOSApp',
  'AndroidApp'
]

const MARKETPLACE = [
  {
    region: 'NA',
    marketplaces: ['BR', 'CA', 'MX', 'US']
  },
  {
    region:'EU',
    marketplaces: ['AE', 'BE', 'DE', 'EG', 'ES', 'FR', 'IN', 'IT', 'NL', 'PL', 'SA', 'SE', 'TR', 'UK', 'ZA']},
  {
    region:'FE',
    marketplaces: ['AU', 'JP', 'SG']},
  {
    region:'CN',
    marketplaces: ['CN']}
]

const OVERLAY = [
    'none',
    'custom',
    'day-over-day',
    'week-over-week',
    'year-over-year'
]

export const platformOptions: SelectProps.Option[] = []
export const marketplaceOptions: any[] = []
export const overlayOptions: SelectProps.Option[] = []

PLATFORM.forEach(each => {
  platformOptions.push({
    label: each,
    value: each
  })
})

MARKETPLACE.forEach(each=>{
  let optionGroup: {}
  let optionItem: any[] = []

  each.marketplaces.forEach(each => {
    return optionItem.push({
      label: each,
      value: each
    });
  })

  optionGroup = {
    label:each.region,
    options: optionItem
  }
  marketplaceOptions.push(optionGroup as ReadonlyArray<SelectProps.Option | SelectProps.OptionGroup>)
})

OVERLAY.forEach(each => {
  overlayOptions.push({
    label: each,
    value: each
  })
})
import React, {Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState,} from 'react';

import './uplotStyle.scss';
import UPlotReact from 'uplot-react';
import uPlot, {AlignedData, Series} from "uplot";
import {verticalAnnotationPlugin} from "./plugins/VerticalAnnotationPlugin";
import legendFormatterPlugin from "./plugins/LegendFormatterPlugin";
import Header from "@amzn/awsui-components-react/polaris/header";
import {ChartData, HorizontalAnnotation} from "../../live-monitoring/generated-src";
import {DateRange} from "../chart-containers/ChartCollection";
import {Spinner} from "@amzn/awsui-components-react";

const DEFAULT_CHART_WIDTH = 900;

const HORIZONTAL_ANNOTATION_COLORS = [
    "#EFB700",
    "#B81D13",
    "#800080"
]

export type ForecastChartProps = {
    chartData: ChartData | undefined;
    dateRange: DateRange
    dataLoading: boolean
    chartSync: uPlot.SyncPubSub
    triggerChartCallOnScaleChange: (resetToDefault: boolean, startTime?: Date, endTime?: Date) => void;
}


export default function ForecastChart(props: ForecastChartProps) {
    if (props.chartData === undefined || props.chartData.datapoints === undefined || props.dataLoading) {
        return (<Spinner size="large"/>)
    }
    const parentRef = useRef<HTMLDivElement>(null)
    const startDateRef = useRef<Date>(props.dateRange.minStart)
    const mouseDragStatus = useRef({
        clickDown: false,
        moved: false
    })

    const startTimeInSeconds = props.dateRange.currentStart.getTime() / 1000
    const endTimeInSeconds = props.dateRange.currentEnd.getTime() / 1000

    const [width, setWidth] = useState<number>(DEFAULT_CHART_WIDTH)
    useAdjustWidthByDivSize(parentRef, setWidth);


    const guidanceNumbers = props.chartData.horizontalAnnotations
        .filter(a => a.startTime < endTimeInSeconds && a.endTime > startTimeInSeconds)
        .sort((a, b) => compareAnnotationStartTime(a, b))
        .map(annotation => `${annotation.name}: ${Math.round(annotation.value).toLocaleString()}`).join(' | ')
    const [seriesLabels, seriesDatapoints]: [uPlot.Series[], number[][]] = parseHorizontalAnnotations(
        props.chartData.horizontalAnnotations, props.chartData.datapoints?.dateDatapoints)

    const options: uPlot.Options = {
        width: width,
        height: 350,
        cursor: getCursorHandlers(props, mouseDragStatus, startDateRef),
        scales: {'x': {min: startTimeInSeconds, max: endTimeInSeconds}},
        series: [
            {label: 'Date'},
            {label: 'p05', points: {show: false}, value: formatToShortValue},
            {label: 'p50', points: {show: false}, value: formatToShortValue},
            {label: 'p95', points: {show: false}, value: formatToShortValue},
            {label: 'actuals', points: {show: false}, stroke: 'green', value: formatToShortValue},
            ...seriesLabels
        ],
        bands: [
            //Between p50 and p05
            {series: [2, 1], fill: "#ece0ed"},
            //Between p95 and p50
            {series: [3, 2], fill: "#eecfed"}
        ],
        axes: [
            {
                label: 'DateTime'
            },
            {
                values: (u, vals) => vals.map(val => formatToShortValue(u, val))
            }
        ],
        plugins: [verticalAnnotationPlugin(props.chartData?.verticalAnnotations), legendFormatterPlugin()]
    }

    const formattedData: AlignedData = [
        props.chartData.datapoints.dateDatapoints,
        props.chartData.datapoints.p05ValueDatapoints,
        props.chartData.datapoints.p50ValueDatapoints,
        props.chartData.datapoints.p95ValueDatapoints,
        props.chartData.datapoints.aDatapoints,
        ...seriesDatapoints
    ]


    return (
        <div ref={parentRef} graph-name={props.chartData.name}>
            <Header
                variant="h1"
                description={guidanceNumbers}
            >
                {props.chartData.name}
            </Header>
            <UPlotReact
                key="hooks-key"
                options={options}
                data={formattedData}
                onCreate={uPlot => {
                    props.chartSync.sub(uPlot)
                }}
                onDelete={uPlot => props.chartSync.unsub(uPlot)}
            />
        </div>
    )
}

function parseHorizontalAnnotations(horizontalAnnotation: HorizontalAnnotation[],
                                    dateValues: number[]): [Series[], number[][]] {
    const seriesMap = new Map()
    const minDate = dateValues[0]
    const maxDate = dateValues[dateValues.length - 1]
    for (const annotation of horizontalAnnotation) {
        const name = annotation.name.includes("Scaling Guidance") ? "Scaling Guidance" : annotation.name;
        if ((annotation.endTime < minDate) || (annotation.startTime > maxDate)) {
            continue;
        }
        if (!seriesMap.has(name)) {
            seriesMap.set(name, new Array(dateValues.length).fill(null));
        }
        let valArray: (number | null)[] = seriesMap.get(name)
        const startIdx = minDate > annotation.startTime ? 0 : dateValues.findIndex(val => val === annotation.startTime)
        const resolution = dateValues[1] - dateValues[0]
        const endTimeToMinute = Math.floor(annotation.endTime / resolution) * resolution
        const endIdx = maxDate < annotation.endTime ? dateValues.length - 1 : dateValues.findIndex(val => val === endTimeToMinute)
        valArray = valArray.fill(annotation.value, startIdx, endIdx + 1)
        seriesMap.set(name, valArray)
    }


    const seriesLabels: uPlot.Series[] = []
    const seriesDatapoints: number[][] = []

    let colorIdx = 0
    for (const [label, datapoints] of seriesMap.entries()) {
        seriesLabels.push({
            label: label,
            stroke: HORIZONTAL_ANNOTATION_COLORS[colorIdx],
            value: formatToShortValue,
            width: 2
        })
        seriesDatapoints.push(datapoints)
        colorIdx++;
    }

    return [seriesLabels, seriesDatapoints]

}

function getCursorHandlers(props: ForecastChartProps, mouseDragStatus: React.MutableRefObject<{ clickDown: boolean; moved: boolean }>, dateOfMouseDown: React.MutableRefObject<Date>) {
    return {
        sync:
            {
                key: props.chartSync.key,
                //Disable default date range sync functionality
                filters: {
                    pub: type => type !== 'mouseup'
                }
            },
        bind: {
            mousedown: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return e => {
                    mouseDragStatus.current.clickDown = true
                    if (self.cursor.idx) {
                        dateOfMouseDown.current = new Date(self.data[0][self.cursor.idx] * 1000)
                    }
                    handler(e)
                    return null;
                }
            },
            mousemove: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return e => {
                    if (mouseDragStatus.current.clickDown) {
                        mouseDragStatus.current.moved = true
                    }
                    handler(e);
                    return null;
                }
            },
            mouseup: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return e => {
                    if (mouseDragStatus.current.clickDown && mouseDragStatus.current.moved) {
                        if (dateOfMouseDown.current && self.cursor.idx) {
                            const dateOfMouseUp = new Date(self.data[0][self.cursor.idx] * 1000)

                            const startDate = dateOfMouseDown.current < dateOfMouseUp ? dateOfMouseDown.current : dateOfMouseUp
                            const endDate = dateOfMouseDown.current < dateOfMouseUp ? dateOfMouseUp : dateOfMouseDown.current
                            props.triggerChartCallOnScaleChange(false, startDate, endDate)
                        }
                    }
                    mouseDragStatus.current = {
                        clickDown: false,
                        moved: false
                    }
                    handler(e);
                    return null;
                }
            },

            dblclick: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return () => {
                    props.triggerChartCallOnScaleChange(true)
                    return null;
                }
            }
        }
    };
}

function useAdjustWidthByDivSize(
    parentRef: React.RefObject<HTMLDivElement>,
    setWidth: Dispatch<SetStateAction<number>>
): void {
    // This effect is only needed for the first render to provide a synchronous update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(
        () => {
            const element = parentRef.current
            if (element) {
                new ResizeObserver(entries => {
                    // Prevent observe notifications on already unmounted component.
                    const width = entries[0].contentBoxSize[0].inlineSize
                    setWidth(prev => prev === width ? prev : width)
                });
            }
        },
        []
    );

    useEffect(() => {
            const element = parentRef.current
            if (element) {
                let connected = true;
                const observer = new ResizeObserver(entries => {
                    // Prevent observe notifications on already unmounted component.
                    if (connected) {
                        const width = entries[0].contentBoxSize[0].inlineSize
                        setWidth(prev => {
                            return prev === width ? prev : width
                        })
                    }

                })

                observer.observe(element);
                return () => {
                    connected = false;
                    observer.disconnect();
                };
            }
        },
        [parentRef]
    );
}

export function formatToShortValue(u: uPlot, rawValue: number) {
    if (rawValue >= 1_000_000) {
        return `${(rawValue / 1_000_000).toFixed(1)}M`
    } else if (rawValue >= 1000) {
        return `${(rawValue / 1000).toFixed(1)}K`
    }
    return rawValue ? rawValue.toFixed(2) : "--"
}

function compareAnnotationStartTime(a: HorizontalAnnotation, b: HorizontalAnnotation): number {
    if (a.startTime > b.startTime){
        return 1;
    } else if (b.startTime > a.startTime) {
        return -1;
    }
    return 0;
}


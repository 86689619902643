import * as React from "react";
import {FC, useEffect, useState} from "react";
import data from './mockData.json'
import {Options} from "uplot";
import UPlotReact from "uplot-react";
import {formatToShortValue} from "../components/chart/ForecastChart";


let dates: number[] = []
let orders: number[] = []
let expected: number[] = []
let highLimit: number[] = []
let lowLimit: number[] = []
data.forEach(each => {
  dates.push(each.timestamp / 1000)
  orders.push(each.orders)
  expected.push(each.expected)
  highLimit.push(each.highLimit)
  lowLimit.push(each.lowLimit)
})


export const OrderRateChart: FC = () => {
  const [imageWidth, setImageWidth] = useState(0);
  const maxImageWidth = 1200;


  const opts: Options = {
    width: imageWidth,
    height: 400,
    scales: {
      x: {
        time: true,
        range: [dates[0], dates[dates.length - 1]],
      }
    },
    series: [
      {label: 'Date'},
      {label: 'Orders', stroke: "blue", width: 2, value: formatToShortValue},
      {label: 'Expected', stroke: "red", width: 2, value: formatToShortValue},
      {label: 'highLimit', value: formatToShortValue},
      {label: 'lowLimit', value: formatToShortValue},
    ],
    bands: [
      //Between highLimit and lowLimit
      {series: [3, 4], fill: "rgba(200,200,200,0.3)"},
    ],
    axes: [
      {
        label: 'DateTime'
      },
      {
        values: (u, vals) => vals.map(val => formatToShortValue(u, val))
      }
    ],
  };


  useEffect(() => {
    function updateImageWidth() {
      const newImageWidth = Math.min(maxImageWidth, window.innerWidth - 40); // Limit image width to 1200px or window width - 40px
      setImageWidth(newImageWidth);
    }

    updateImageWidth();
    window.addEventListener('resize', updateImageWidth);

    return () => {
      window.removeEventListener('resize', updateImageWidth);
    };
  }, [maxImageWidth]);

  return (
      <div>
        <UPlotReact
            options={opts}
            data={[dates, orders, expected, highLimit, lowLimit]}
        />
      </div>
  );
}



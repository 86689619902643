import ChartCollection from "../../components/chart-containers/ChartCollection";
import React, {useContext, useMemo} from "react";
import ExpandableChartContainer, {
    ExpandableChartContainerProps,
    StaticExpandableChartContainerProps
} from "../../components/chart-containers/ExpandableChartContainer";
import {ParentToChildMapping, ProfileGroup} from "../../config/ProfileGroupMappings";
import {useLocation} from "react-router-dom";
import {GroupsContext, ProfileContext} from "../../context/ForecastProfileContext";
import {Spinner} from "@amzn/awsui-components-react";
import FlatChartContainer, {FlatChartContainerProps, StaticFlatChartContainerProps} from "../../components/chart-containers/FlatChartContainer";

export interface FilterUrlParams {
    groups: string[],
    regions: string[]
}

//Use links to determine which things to push
export default function FilterContent() {

    const profiles = useContext(ProfileContext);
    if (profiles.length === 0) {
        return (<Spinner size="large"/>)
    }

    const groupMapping = useContext(GroupsContext)
    const {search} = useLocation();
    const pathParams = new URLSearchParams(search)
    const selectedGroups = Array.from(new Set(pathParams.get("groups")?.split(","))) || []
    const selectedRegions = Array.from(new Set(pathParams.get("regions")?.split(","))) || []


    const profileToRegionMapping: Map<string, string> = useMemo(() => {
            return profiles.reduce(
                (profileToRegionMap, profile) => {
                    profileToRegionMap.set(profile.forecastProfileId, profile.region)
                    return profileToRegionMap
                }, new Map<string, string>)
        }, [profiles]
    )


    let chartContainerProperties: [
        containerDefinition:
            ((componentProp: ExpandableChartContainerProps) => JSX.Element) |
            ((componentProp: FlatChartContainerProps) => JSX.Element),
        componentProps: StaticExpandableChartContainerProps[] | StaticFlatChartContainerProps[],
        activeProfileIds: string[],
        cardsPerRow: number]

    const selectedProfileMappings = getSelectedProfilesFromGroup(selectedGroups, groupMapping)
    if (selectedGroups.length > 0 && selectedRegions.length === 0) {
        chartContainerProperties = generateExpandableCharts(selectedProfileMappings)
    } else {
        chartContainerProperties = generateFlattenedCharts(selectedProfileMappings, selectedRegions, profileToRegionMapping)
    }

    const [chartComponentFactory, chartComponentProps, activeProfileIds, cardsPerRow] = chartContainerProperties

    const searchContainerProps = {
        forecastDimensionDropdown: {
            selectedGroups: selectedGroups,
            selectedRegions: selectedRegions
        }
    }

    return (
        <ChartCollection
            searchContainerProps={searchContainerProps}
            initialActiveProfiles={activeProfileIds}
            containerDefinition={chartComponentFactory}
            staticContainerProps={chartComponentProps}
            addDynamicProfileSetting={true}
            cardsPerRow={cardsPerRow}/>
    )

}

function getSelectedProfilesFromGroup(selectedGroups: string[], groupMapping: Map<ProfileGroup, ParentToChildMapping[]>) {
    const selectedProfilesFromGroup: ParentToChildMapping[] = []
    if (selectedGroups.length === 0) {
        selectedGroups = [...groupMapping.keys()]
    }

    selectedGroups.forEach(group => {
        const values = groupMapping.get(group as ProfileGroup)
        if (values !== undefined) {
            selectedProfilesFromGroup.push(...values);
        }
    })
    return selectedProfilesFromGroup
}

function generateExpandableCharts(selectedProfileMappings: ParentToChildMapping[]):
    [
        componentFactory: (componentProp: ExpandableChartContainerProps) => JSX.Element,
        staticComponentProps: StaticExpandableChartContainerProps[],
        activeProfiles: string[],
        cardsPerRow: number] {

    const staticContainerProps: StaticExpandableChartContainerProps[] = []
    const activeProfiles: string[] = []
    selectedProfileMappings.forEach(profileMapping => {
        staticContainerProps.push({profileMapping})
        activeProfiles.push(profileMapping.parentId)
    })
    return [componentProp => <ExpandableChartContainer {...componentProp}/>, staticContainerProps, activeProfiles, 1]
}

function generateFlattenedCharts(
    selectedProfileMappings: ParentToChildMapping[],
    selectedRegions: string[],
    profileToRegionMapping: Map<string, string>):
    [
        componentFactory: (componentProp: FlatChartContainerProps) => JSX.Element,
        staticComponentProps: StaticFlatChartContainerProps[],
        activeProfiles: string[],
        cardsPerRow: number
    ] {
    const activeProfiles: string[] = []

    //Using inefficient O(n)^2 solution to maintain ordering of selected fields
    selectedProfileMappings.forEach(profileMappings => {
        selectedRegions.forEach(region => {
            if (profileToRegionMapping.get(profileMappings.parentId) === region) {
                activeProfiles.push(profileMappings.parentId)
            }
            profileMappings.childId.forEach(child => {
                if (profileToRegionMapping.get(child) === region) {
                    activeProfiles.push(child)
                }
            })
        })
    })

    const containerProps = activeProfiles.map(profile => {
        return {forecastProfileId: profile}
    })


    return [componentProp => <FlatChartContainer {...componentProp}/>, containerProps, activeProfiles, 2]
}




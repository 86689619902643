import Header from "@amzn/awsui-components-react/polaris/header";
import {FC, useState} from "react";
import {
  Alert,
  DateRangePicker,
  ExpandableSection,
  Link,
  Select
} from "@amzn/awsui-components-react";
import {OrderRateChart} from "./OrderRateChart";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  OptionDefinition
} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import {
  DATE_RANGE_PICKER_LABELS,
  isValidRangeFunction,
  relativeOptions
} from "./DateRangeFilterConfig";
import {DateRangePickerProps} from "@amzn/awsui-components-react/polaris/date-range-picker";
import {marketplaceOptions, overlayOptions, platformOptions} from "./SelectionFilterConfig";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import {Calculator} from "./Calculator";


export const ORDPage: FC = () => {

  const [dateRange, setDateRange] = useState<DateRangePickerProps.Value | null>({
    key: 'previous-3-minutes',
    amount: 3,
    unit: 'minute',
    type: 'relative',
  })
  const [selectedPlatform, setSelectedPlatform] = useState<OptionDefinition | null>(null);
  const [selectedMarketplace, setSelectedMarketplace] = useState<readonly OptionDefinition[]>([]);
  const [selectedOverlay, setSelectedOverlay] = useState<OptionDefinition | null>(null);


  return (
      <>
        <Alert
            dismissible
            statusIconAriaLabel="Info"
        >
          If you're looking for the latest order rate data, not historical order rate data, use
          this <Link external href={'https://w.amazon.com/bin/view/OrderRateData'}>wiki
          dashboard</Link>.

          The green expected line and prediction band are not based on forecasting, instead on
          week-over-week averages. For order rate data graphed next to the peak forecast see:

          <Link external
                href={'https://cloudtune-monitoring.aka.amazon.com/'}>cloudtune-monitoring</Link>
        </Alert>
        <Header
            counter="(3)"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName={"share"}>tinyURL</Button>
                <Button iconName={"download"}>CSV</Button>
              </SpaceBetween>
            }
            info={<Link variant="info">Info</Link>}
        >
          Order Rate Data
        </Header>
        <SpaceBetween size={'xxxs'} direction={"horizontal"}>
          <DateRangePicker
              value={dateRange}
              onChange={(changeDetail) => setDateRange(changeDetail.detail.value)}
              relativeOptions={relativeOptions}
              i18nStrings={DATE_RANGE_PICKER_LABELS}
              isValidRange={isValidRangeFunction}
              placeholder="Filter by a date and time range"
          />
          <Select
              selectedOption={selectedPlatform}
              onChange={({detail}) => setSelectedPlatform(detail.selectedOption as OptionDefinition)}
              options={platformOptions}
              placeholder={"Platform"}
          />
          <Multiselect
              selectedOptions={selectedMarketplace}
              onChange={({detail}) =>
                  setSelectedMarketplace(detail.selectedOptions)
              }
              options={marketplaceOptions}
              placeholder={"Marketplace(s)"}
          />
          <Select
              selectedOption={selectedOverlay}
              onChange={({detail}) => setSelectedOverlay(detail.selectedOption as OptionDefinition)}
              options={overlayOptions}
              placeholder={"Overlay"}
          />
        </SpaceBetween>
        <ExpandableSection headerText="Calculator">
          <Calculator/>
        </ExpandableSection>
        <OrderRateChart/>
      </>
  )
}
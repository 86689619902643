import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import {ColumnLayout, ExpandableSection} from "@amzn/awsui-components-react";
import {ParentToChildMapping} from "../../config/ProfileGroupMappings";
import {ChartData} from "../../live-monitoring/generated-src";
import ForecastChart, {ForecastChartProps} from "../chart/ForecastChart";
import {ActionableCollectionCardProps, CollectionCardProps} from "./ChartCollection";

export interface StaticExpandableChartContainerProps {
    profileMapping: ParentToChildMapping
}

export interface ExpandableChartContainerProps extends StaticExpandableChartContainerProps, CollectionCardProps, ActionableCollectionCardProps, ForecastChartProps {}

export default function ExpandableChartContainer(props: ExpandableChartContainerProps) {

    const childCharts: ChartData[] = []

    props.profileMapping.childId.forEach(childId => {
            const chartData: ChartData | undefined = props.chartDataMap.get(childId)
            if (chartData) {
                childCharts.push(chartData);
            }
        }
    )

    const parent: ChartData | undefined = props.chartDataMap.get(props.profileMapping.parentId)
    const childIds = props.profileMapping.childId
    return (
        <Container
            footer={
                props.profileMapping.childId.length !== 0 &&
                <ExpandableSection header="Expand to Individual Forecast Profiles"
                                   variant="footer"
                                   onChange={expanded => {
                                       if (expanded) {
                                           props.addActiveCharts(childIds)
                                       } else {
                                           props.removeCharts(childIds)
                                       }
                                   }}>
                    <ColumnLayout columns={2} variant={"text-grid"}>
                        {
                            childCharts.map(chart => {
                                return (
                                        <ForecastChart
                                            chartData={chart}
                                            dateRange={props.dateRange}
                                            dataLoading={props.dataLoading}
                                            chartSync={props.chartSync}
                                            triggerChartCallOnScaleChange={props.triggerChartCallOnScaleChange}
                                            key={chart.forecastProfileId}/>
                                   )
                            })}
                    </ColumnLayout>
                </ExpandableSection>
            }>
                <ForecastChart chartData={parent}
                               dateRange={props.dateRange}
                               dataLoading={props.dataLoading}
                               chartSync={props.chartSync}
                               triggerChartCallOnScaleChange={props.triggerChartCallOnScaleChange}
                />
        </Container>
    )
}
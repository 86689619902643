import ChartCollection from "../../components/chart-containers/ChartCollection";
import React, {useContext, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {ProfileContext} from "../../context/ForecastProfileContext";
import {Alert, Spinner} from "@amzn/awsui-components-react";
import FlatChartContainer from "../../components/chart-containers/FlatChartContainer";

export interface SearchUrlParams {
    ids: string[]
}

//Use links to determine which things to push
export default function SearchContent() {

    const profiles = useContext(ProfileContext);
    if (!profiles.length) {
        return (<Spinner size="large"/>)
    }

    const nameToProfileMap: Map<string, string> = useMemo(() => {
            return profiles.reduce(
                (profileToRegionMap, profile) => {
                    profileToRegionMap.set(profile.name, profile.forecastProfileId)
                    return profileToRegionMap
                }, new Map<string, string>)
        }, [profiles]
    )


    const {search} = useLocation();
    const pathParams = new URLSearchParams(search)
    const profilesFromQuery = Array.from(new Set(pathParams.get("ids")?.split(",")))
    const validProfileNames: string[] = []
    const validProfileIds: string[] = []
    const profilesNotFound: string[] = []
    profilesFromQuery.forEach(name => {
        const id = nameToProfileMap.get(name)
        if (id) {
            validProfileIds.push(id)
            validProfileNames.push(name)
        } else {
            profilesNotFound.push(name)
        }
    })

    const searchContainerProps = {
        individualForecastSearchProps: {
            selectedProfiles: validProfileNames
        }
    }

    const containerProps = validProfileIds.map(id => {
        return {forecastProfileId: id}
    })

    return (
        <>
            {profilesNotFound.length > 0 &&
                <Alert dismissible
                       statusIconAriaLabel="Error"
                       type="error"
                       header="Some profiles in the URL could not be found"> Some forecasts you listed cannot be found.
                    Please check if there are typos within the url for the following:
                    {profilesNotFound.join(",")}
                </Alert>
            }
            <ChartCollection
                searchContainerProps={searchContainerProps}
                initialActiveProfiles={validProfileIds}
                containerDefinition={componentProp => <FlatChartContainer {...componentProp}/>}
                staticContainerProps={containerProps}
                addDynamicProfileSetting={true}
                cardsPerRow={2}/>
        </>

    )

}





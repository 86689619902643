import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import {useLocation} from "react-router-dom";

export default function TopToolBar() {
    const {search} = useLocation();
    const pathParams = new URLSearchParams(search)
    const selectedRegions = Array.from(new Set(pathParams.get("regions")?.split(","))) || []
    let classicUrl = "https://cloudtune-monitoring.aka.amazon.com/?no_redirect=true"
    if (selectedRegions.length){
        classicUrl += `&region=${selectedRegions.join(",")}`
    }

    return (<TopNavigation
        identity={{
            href: "#",
            title: "CloudTune Forecast Monitoring"
        }}
        utilities={[
            {
                type: "button",
                text: "Classic Version",
                external: true,
                href: classicUrl
            },
            {
                type: "button",
                text: "Forecast Revisions",
                external: true,
                href: "https://w.amazon.com/bin/view/CloudTune/cloudtune-monitoring/"
            },
            {
                type: "button",
                text: "Forecasting Dashboard",
                external: true,
                href: "https://forecasts.cloudtune.a2z.com/#/"
            },
            {
                type: "button",
                text: "HVE Planner",
                external: true,
                href: "https://hve.cloudtune.a2z.com/#/"
            },
            {
                type: "button",
                text: "Active Deviations",
                external: true,
                href: "https://sim.amazon.com/dashboards/CloudTune/Forecast%20Monitoring/Active%20Deviations%20-%20CloudTune%20Forecast%20Monitoring/36b0035a-9bd5-47b2-bac1-dcde440f702e"
            },

            {
                type: "menu-dropdown",
                text: "Help",
                items: [
                    {
                        id: "FAQ",
                        text: "FAQ",
                        external: true,
                        href: "https://w.amazon.com/bin/view/CloudTune/FAQ/"
                    },
                    {
                        id: "User Guide",
                        text: "User Guide",
                        external: true,
                        href: "https://w.amazon.com/bin/view/CloudTune/FAQ/MonitoringDashboardUserGuide"
                    },
                    {
                        id: "feedback",
                        text: "Send us feedback",
                        external: true,
                        href: "https://issues.amazon.com/issues/create?assignedFolder=c6f681c3-ecb0-47f9-b1b7-22743ee7fd49&title=CloudTune+Dashboard+Feedback"
                    },
                ]
            },




        ]}
        i18nStrings={
            {
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All"
            }
        }/>);
}
import {FC, ReactNode} from "react";

import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import {Box, ColumnLayout, ExpandableSection, Icon, Popover} from "@amzn/awsui-components-react";


const Info: FC<{ header?: string; content: ReactNode }> = (props) => (
    <Box color="text-status-info" display="inline">
      <Popover
          header={props.header}
          size="medium"
          triggerType="text"
          position={'top'}
          content={<small>{props.content}</small>}
          renderWithPortal={true}
      >
        <Icon name={"status-info"}/>
      </Popover>
    </Box>
);

export const Calculator: FC = () => {
  return (
      <>
        <Container
            footer={
              <ExpandableSection
                  header="Settings"
                  variant="footer"
              >
                Place additional form fields here.
              </ExpandableSection>
            }
        >
          <ColumnLayout columns={6} variant="text-grid">
            <div>
              <SpaceBetween size={"xxs"} direction={"horizontal"}>
                <Box variant={"awsui-key-label"}>Max Drop Percentage</Box>
                <Info content={
                  <span>Maximum drop percent (order loss) in the selected area.</span>
                }/>
              </SpaceBetween>
              <div>--</div>
            </div>
            <div>
              <SpaceBetween size={"xxs"} direction={"horizontal"}>
                <Box variant="awsui-key-label">Outage Minutes</Box>
                <Info
                    content={
                      <>
                        <span>Number of minutes below the</span>
                        <b> drop limit </b>
                        <span>line of the selected area; highlighted by the red-filled area</span>
                      </>
                    }/>
              </SpaceBetween>
              <div>--</div>
            </div>
            <div>
              <SpaceBetween size={"xxs"} direction={"horizontal"}>
                <Box variant="awsui-key-label">Recovery Minutes</Box>
                <Info
                    content={
                      <>
                        <span>Number of minutes above the </span>
                        <b>Adjusted Expected </b>
                        <span>line of the selected area; highlighted by the green-filled area</span>
                      </>
                    }/>
              </SpaceBetween>
              <div>--</div>
            </div>
            <div>
              <SpaceBetween size={"xxs"} direction={"horizontal"}>
                <Box variant="awsui-key-label">Orders Impacted</Box>
                <Info
                    content={
                      <>
                        Orders Impacted (or Orders Lost) = Adjusted Expected - Orders, where orders
                        are below Drop Limit in the selected area; highlighted by the red-filled
                        area
                      </>
                    }/>
              </SpaceBetween>
              <div>--</div>
            </div>
            <div>
              <SpaceBetween size={"xxs"} direction={"horizontal"}>
                <Box variant="awsui-key-label">Recovery Orders</Box>
                <Info
                    content={
                      <>
                        Recovered Orders = Orders - Adjusted Expected, where orders are above
                        Adjusted Expected in the selected area; highlighted by the green-filled
                        area
                      </>
                    }/>
              </SpaceBetween>
              <div>--</div>
            </div>
            <div>
              <SpaceBetween size={"xxs"} direction={"horizontal"}>
                <Box variant="awsui-key-label">Adjusted Orders Impacted</Box>
                <Info content={
                  <>
                    Adjusted Orders Impacted (or adjusted orders lost) = Orders Impacted - Recovered Orders
                  </>
                }/>
              </SpaceBetween>
              <div>--</div>
            </div>
          </ColumnLayout>
        </Container>
      </>
  )
}
import * as React from "react";
import {Alert, Spinner} from "@amzn/awsui-components-react";
import Markdown from "marked-react";
import {useEffect, useState} from "react";
import LiveMonitoringApi from "../../live-monitoring/LiveMonitoringApi";

export default () => {
    const api = LiveMonitoringApi()
    const [open, setOpen] = useState(true);
    const [text, setText] = useState<string | undefined>();

    useEffect(() => {
        api.getAnnouncement()
            .then(result => {
                return result.data;
            })
            .then(announcementText => {
                setText(announcementText['text'])
            });
    }, [])

    return (
        <>
            {open && (text ? <Alert
                    header="Announcements"
                    dismissible={true}
                    onDismiss={() => setOpen(false)}
                >
                    <Markdown breaks={true} gfm={true}>{text}
                    </Markdown>
                </Alert> :
                <Spinner size="large"/>)
            }
        </>

    )
}
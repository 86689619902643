import {Multiselect, MultiselectProps, SpaceBetween, Spinner} from "@amzn/awsui-components-react"
import * as React from "react";
import {OptionDefinition} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import {useHistory} from "react-router-dom";
import {useContext} from "react";
import {GroupsContext, RegionsContext} from "../../context/ForecastProfileContext";


export interface ForecastDimensionDropdownProps {
    selectedGroups?: string[],
    selectedRegions?: string[]
}

// If setFunctions undefined, push to history
//
export default function ForecastDimensionDropdown(props: ForecastDimensionDropdownProps) {
    const history = useHistory();
    const groupMapping = useContext(GroupsContext)
    const regions = useContext(RegionsContext)

    if (!regions.length || !groupMapping.size) {
        return <Spinner size="large"/>
    }

    let groupOptions: MultiselectProps.Option[] = []
    groupMapping.forEach((val, key) => {
        groupOptions.push({
            label: key,
            value: val[0].parentId
        })
    })

    groupOptions = groupOptions.sort((a, b) => {
        if (a.label === undefined) {
            return -1
        }
        if (b.label === undefined) {
            return 1
        }
        if (a.label > b.label) {
            return 1
        }
        return -1
    })


    const [selectedProfileGroupOptions, setSelectedProfileGroupOptions] = React.useState<readonly OptionDefinition[]>(
        () => {
            const selectedOptions = props.selectedGroups?.map(group => groupOptions.find(opt => opt.label === group)) || []
            return selectedOptions as OptionDefinition[];
        });

    const onGroupSelectionChange = ((newSelection: string[]) =>
            history.push(generateUrl(newSelection, props.selectedRegions || [])))

    const regionOptions: MultiselectProps.Option[] = regions.sort().map((key, idx) => {
        return {
            label: key,
            value: idx.toString()
        }
    })

    const [selectedRegionOptions, setSelectedRegionOptions] = React.useState<readonly OptionDefinition[]>(
        () => {
            const selectedOptions = props.selectedRegions?.map(region => regionOptions.find(opt => opt.label === region)) || []
            return selectedOptions as OptionDefinition[];
        });


    const regionSelectionChange = ((newSelection: string[]) =>
            history.push(generateUrl(props.selectedGroups || [], newSelection)))


    return (
        <>
            <SpaceBetween direction='horizontal' size='xl'>
                <Multiselect
                    selectedOptions={selectedProfileGroupOptions}
                    onChange={(event) => {
                        setSelectedProfileGroupOptions(event.detail.selectedOptions)
                        onGroupSelectionChange(event.detail.selectedOptions.map(opt => opt.label || ""))
                    }
                    }
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    options={groupOptions}
                    placeholder="Search for forecast profile group - Start typing"
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                />
                <Multiselect
                    selectedOptions={selectedRegionOptions}
                    onChange={(event) => {
                        setSelectedRegionOptions(event.detail.selectedOptions)
                        regionSelectionChange(event.detail.selectedOptions.map(opt => opt.label || ""))
                    }}
                    options={regionOptions}
                    placeholder="Search for regions/marketplaces - Start typing"
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                />
            </SpaceBetween>

        </>)
}

function generateUrl(groups: string[], regions: string[]) {
    if (groups.length === 0 && regions.length === 0) {
        return "/"
    }

    let basePath = "/filter?"
    if (groups.length > 0) {
        basePath = `${basePath}groups=${groups.join(",")}`
    }

    if (regions.length > 0) {
        basePath = `${basePath}&regions=${regions.join(",")}`
    }
    return basePath
}
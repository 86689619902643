import {Route, Switch} from 'react-router-dom';
import TopToolBar from './TopToolBar';
import Homepage from "../pages/home";
import Filterpage from "../pages/filter"
import Searchpage from "../pages/search"

import * as React from "react";
import {GroupsContext, ProfileContext, RegionsContext} from "../context/ForecastProfileContext";
import {useEffect, useState} from "react";
import {ForecastProfile} from '../live-monitoring/generated-src';
import LiveMonitoringApi from "../live-monitoring/LiveMonitoringApi";
import {PROFILE_GROUP_MAPPING} from "../config/ProfileGroupMappings";
import AnnouncementBanner from "./announcement/AnnouncementBanner";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import {ORDPage} from "../ORD/ORDPage";

export default function App() {
    const api = LiveMonitoringApi()
    const [profileState, setProfileState] = useState<ForecastProfile[]>([]);
    const [regionState, setRegionState] = useState<string[]>([]);
    const groups = PROFILE_GROUP_MAPPING()
    useEffect(() => {
        api.listProfiles()
            .then(resp => resp.data)
            .then(profiles => {
                setProfileState(profiles);
                const regions = new Set<string>()
                profiles.map(profile => {
                    regions.add(profile.region)
                })
                setRegionState([...regions])
            })
    }, [])

    return (
        <>
            <TopToolBar/>
            <AnnouncementBanner/>
            <ProfileContext.Provider value={profileState}>
                <RegionsContext.Provider value={regionState}>
                    <GroupsContext.Provider value={groups}>
                        <AppLayout
                            content={
                                <Switch>
                                    <Route exact path="/filter" component={Filterpage}/>
                                    <Route exact path="/search" component={Searchpage}/>
                                    <Route exact path="/" component={Homepage}/>
                                    <Route exact path="/ORD" component={ORDPage}/>
                                </Switch>
                            }
                            contentType="cards"
                            navigationHide={true}
                            toolsHide={true}
                            disableContentPaddings={false}
                        />
                    </GroupsContext.Provider>
                </RegionsContext.Provider>
            </ProfileContext.Provider>
        </>
    );
}






import { DateRangePickerProps } from '@amzn/awsui-components-react/polaris/date-range-picker';


export const DATE_RANGE_PICKER_LABELS: DateRangePickerProps.I18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const t = e.amount === 1 ? e.unit : `${e.unit}s`;
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (t === 1 ? e : `${e}s`),
  dateTimeConstraintText: 'Range must be between 6 and 30 days. Use 24 hour format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear and dismiss',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: DateRangePickerProps.RelativeOption[] = [
  {
    key: 'previous-1-hour',
    amount: 1,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-6-hour',
    amount: 6,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-12-hours',
    amount: 12,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-1-day',
    amount: 1,
    unit: 'day',
    type: 'relative',
  },
  {
    key: 'previous-3-day',
    amount: 3,
    unit: 'day',
    type: 'relative',
  },
  {
    key: 'previous-1-week',
    amount: 1,
    unit: 'week',
    type: 'relative',
  },
];

export const isValidRangeFunction: DateRangePickerProps.ValidationFunction = (
    range: DateRangePickerProps.Value | null,
) => {
  if (!range) {
    return {
      valid: false,
      errorMessage: 'null',
    };
  }

  if (range.type === 'absolute') {
    const [startDateWithoutTime] = range.startDate.split('T');
    const [endDateWithoutTime] = range.endDate.split('T');

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.',
      };
    }
  } else if (range.type === 'relative') {
    if (isNaN(range.amount)) {
      return {
        valid: false,
        errorMessage: 'The selected date range is incomplete. Specify a duration for the date range.',
      };
    }
  }
  return { valid: true };
};

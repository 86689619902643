import React from "react";
import ForecastChart, {ForecastChartProps} from "../chart/ForecastChart";
import {CollectionCardProps} from "./ChartCollection";

export interface StaticFlatChartContainerProps {
    forecastProfileId: string
}
export interface FlatChartContainerProps extends StaticFlatChartContainerProps, CollectionCardProps, ForecastChartProps {}

export default function FlatChartContainer(props: FlatChartContainerProps) {
    return (
            <ForecastChart chartData={props.chartDataMap.get(props.forecastProfileId)}
                           dateRange={props.dateRange}
                           dataLoading={props.dataLoading}
                           chartSync={props.chartSync}
                           triggerChartCallOnScaleChange={props.triggerChartCallOnScaleChange}
            />
    )
}